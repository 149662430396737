import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui-react';
import Config from './organization_config.json';
import Sidebar from './Sidebar.js'
import SSO from './sso'

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
                organizationConfig: null,
                definitionList: null,
                definitionLink: "https://staging-api-app-eumais.euca.tur.br/swagger"
            }
            //this.swaggerhub = this.swaggerhub.bind(this)
        this.getOrganizationData = this.getOrganizationData.bind(this)
        this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
    }

    componentWillMount() {
        this.setState({
            organizationConfig: Config.orgData,
        })
    }



    getOrganizationData() {
        this.setState({
            definitionList: Config.api_docs
        })


    }

    updateDefinitionLink(newLink) {
        this.setState({
            definitionLink: newLink
        })
    }

    render() {
        if (this.state.definitionLink === "SSO") {
            return ( <
                div className = "App" >
                <
                Sidebar organizationConfig = { this.state.organizationConfig }
                definitionList = { this.state.definitionList }
                updateDefinitionLink = { this.updateDefinitionLink }
                getOrganizationData = { this.getOrganizationData }
                />

                <
                div id = "api-data" >
                <
                SSO /
                >
                <
                /div> </div >
            );
        } else {

            return ( <
                div className = "App" >
                <
                Sidebar organizationConfig = { this.state.organizationConfig }
                definitionList = { this.state.definitionList }
                updateDefinitionLink = { this.updateDefinitionLink }
                getOrganizationData = { this.getOrganizationData }
                />

                <
                div id = "api-data" >
                <
                SwaggerUI url = { this.state.definitionLink }
                docExpansion = "list" /
                >
                <
                /div> </div >
            );
        }
    }
}

export default App;