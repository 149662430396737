import React from 'react';

const SSO = () => {


        return ( < div className = "sso" >
            <h1>Single Sign On(SSO) </h1>

            <p>Esta seção fornece diretrizes para utilização do serviço de SSO da eucatur. </p>

            <h3>Definição de termos </h3>

            <p>Os seguintes termos se relacionam ao uso de Keycloak com Client EUCATUR: </p>

            <ul>
                <li><strong> Autenticação </strong> - os usuários são autenticados por meio de seu e-mail e senha.</li>
                <li><strong> Autorização </strong> - o acesso autorizado é verificado pelo Client EUCATUR por meio das funções definidas no Keycloak.</li>
                <li><strong> Provedor de identidade(IDP) </strong> - Keycloak mantém os usuários e suas funções, fornecendo, assim, autorização e autenticação.</li>
                <li><strong > SAML 2.0 </strong> - protocolo para a troca de dados de autenticação e autorização entre o provedor de identidade da eucatur e o client do keycloak.</li>
            </ul>

            <h3> Fluxo de Autenticação do IDP </h3>

            <center>
                <img height = "360px" src = "images/sso_eucatur_fluxo.png" alt = "logo" />
            </center>

            <h3>Autenticação - SSO </h3>

            <p>
            Toda a comunicação e o fluxo de autenticação do nosso SSO será através do protocolo <code> openid - connect </code>, 
            no qual forneceremos todos os necessário para realizar a configuração e o acesso.
            </p>

            <p>
                <strong> NOTA: </strong> Para fornecermos os dados do quadro abaixo, o cliente consumidor do nosso SSO deverá disponibilizar uma  
                <code> url de redirecionamento </code>, para qual deve ser redirecionado após ter uma autenticação realizada com sucesso. 
            </p>

            <div style = {{boxSizing: "border-box" }}>
                <center>
                <table>
                    <tbody>
                        <tr>
                            <th> Protocolo de segurança </th> 
                            <td> Dados de Configurações - Ambiente de homologação </td> 
                        </tr>
                        <tr>
                            <th>OAuth 2.0 e OIDC</th> 
                            <td>
                                <strong> URL de autorização: </strong> https://sso.euca.tur.br/auth/realms/eulabs
                                <br></br>
                                <br></br>
                                <strong>Realm:</strong> eulabs
                                <br></br>
                                <br></br>
                                <strong>Resource/ClientID:</strong> account-xxxx
                                <br></br>
                                <br></br>
                                <strong>Secret:</strong> Será enviado um hash
                                <br></br>
                                <br></br>
                                <strong>Scopes:</strong> <code>profile</code> <code>email</code>
                            </td>
                        </tr>
                    </tbody> 
                </table>
                </center>
            </div>

            <h3>Keycloak Endpoints</h3>
            <p>

                Keycloak expõe uma variedade de pontos de extremidades <b>REST</b> para a implementação dos fluxos <b>OAuth 2.0</b>.
            </p>

            <div style={{paddingLeft:20}}>
                <h4>Endpoint de configuração do OpenID</h4>
                <p>O ponto de extremidade de configuração é como o diretório raiz. Ele retorna todos os outros pontos de extremidade disponíveis, escopos e declarações com suporte e algoritmos de assinatura .</p>
                <b>URL</b><code className="type_method">GET</code> <code>https://sso.euca.tur.br/auth/realms/eulabs/.well-known/openid-configuration</code>
                <p><code>ROTA PÚBLICA</code></p>

                <h4>Endpoint de Autorização</h4>
                <p>
                    O Endpoint de Autorização é responsável pelo fluxo de login para obter o código de autorização OAuth 2.0, conforme <a target="_black" href="https://auth0.com/docs/flows/authorization-code-flow"> OAuth 2.0 Authorization Code Flow</a>. 
                    O KeyCloak redireciona o usuário para uma página de login se nenhum cookie de login estiver ativo.
                </p>
                <p><b>NOTA:</b> Está disponíveis como <code>authorization_endpoint</code> na resposta do endpoint de configuração do OpenID.</p>
                <p>
                    <b>URL</b> <code className="type_method">GET</code>  <code>https://sso.euca.tur.br/auth/realms/eulabs/protocol/openid-connect/auth</code>

                    <div style = {{boxSizing: "border-box" }}>
                        <center>
                        <table>
                            <tbody>
                                <tr>
                                    <th> Tipo do Paramêtro </th> 
                                    <td> Paramêtro </td> 
                                    <td> Valor </td> 
                                </tr>
                                <tr>
                                    <th>Query</th> 
                                    <th><code>response_type</code></th> 
                                    <td>
                                        <code>code</code>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Query</th> 
                                    <th><code>redirect_uri</code></th> 
                                    <td>
                                    Sua URL de callback
                                    </td>
                                </tr>
                                <tr>
                                    <th>Query</th> 
                                    <th><code>client_id</code></th> 
                                    <td>
                                        informar seu client_id
                                    </td>
                                </tr>
                                <tr>
                                    <th>Query</th> 
                                    <th><code>scope</code></th> 
                                    <td>
                                        <code>openid profile email</code>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Query</th> 
                                    <th><code>state</code></th> 
                                    <td>
                                     informe um valor qualquer para realizar a validação da origem da solitação
                                    </td>
                                </tr>
                            </tbody> 
                        </table>
                        </center>
                  </div>
                </p>

                <h4>Endpoint de Token de Acesso</h4>
                <p>
                    O ponto de extremidade do token nos permite recuperar um token de acesso, token de atualização ou token id. 
                </p>

                <p><b>NOTA:</b> Está disponíveis como <code>token_endpoint</code> na resposta do endpoint de configuração do OpenID.</p>

                <p>
                    <b>URL</b> <code className="type_method">POST</code>  <code>https://sso.euca.tur.br/auth/realms/eulabs/protocol/openid-connect/token</code>

                    <div style = {{boxSizing: "border-box" }}>
                        <center>
                        <table>
                            <tbody>
                                <tr>
                                    <th> Tipo do Paramêtro </th> 
                                    <td> Paramêtro </td> 
                                    <td> Valor </td> 
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>code</code></th> 
                                    <td>
                                        Informe seu token de autorização
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>redirect_uri</code></th> 
                                    <td>
                                        Sua URL de callback
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>client_id</code></th> 
                                    <td>
                                        informar seu client_id
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>grant_type</code></th> 
                                    <td>
                                      <code>authorization_code</code>
                                    </td>
                                </tr>
                            </tbody> 
                        </table>
                        </center>
                  </div>
                </p>


                <h4>Endpoint de Atualização de Token</h4>
                <p>
                    O ponto de extremidade de atualização token pode ser usado quando temos um token de atualização válido de uma chamada anterior para o terminal do token.
                </p>

                <p><b>NOTA:</b> Está disponíveis como <code>token_endpoint</code> na resposta do endpoint de configuração do OpenID.</p>

                <p>
                    <b>URL</b> <code className="type_method">POST</code>  <code>https://sso.euca.tur.br/auth/realms/eulabs/protocol/openid-connect/token</code>

                    <div style = {{boxSizing: "border-box" }}>
                        <center>
                        <table>
                            <tbody>
                                <tr>
                                    <th> Tipo do Paramêtro </th> 
                                    <td> Paramêtro </td> 
                                    <td> Valor </td> 
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>refresh_token</code></th> 
                                    <td>
                                        Informe seu token de atualização
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>client_id</code></th> 
                                    <td>
                                        informar seu client_id
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>client_secret</code></th> 
                                    <td>
                                      Seu hash do client
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>grant_type</code></th> 
                                    <td>
                                    <code>refresh_token</code>
                                    </td>
                                </tr>
                            </tbody> 
                        </table>
                        </center>
                  </div>
                </p>



                <h4>Endpoint de Informações do Usuário</h4>
                <p>
                    O ponto de extremidade de informações do usuário recupera os dados do perfil do usuário através de uma token de acesso válido.
                </p>

                <p><b>NOTA:</b> Está disponíveis como <code>userinfo_endpoint</code> na resposta do endpoint de configuração do OpenID.</p>

                <p>
                    <b>URL</b> <code className="type_method">GET</code>  <code>https://sso.euca.tur.br/auth/realms/eulabs/protocol/openid-connect/userinfo</code>

                    <div style = {{boxSizing: "border-box" }}>
                        <center>
                        <table>
                            <tbody>
                                <tr>
                                    <th> Tipo do Paramêtro </th> 
                                    <td> Paramêtro </td> 
                                    <td> Valor </td> 
                                </tr>
                                <tr>
                                    <th>Header</th> 
                                    <th><code>Authorization</code></th> 
                                    <td>
                                        Informe seu token de acesso
                                    </td>
                                </tr>
                            </tbody> 
                        </table>
                        </center>
                  </div>
                </p>



                <h4>Endpoint de Introspecção do Token</h4>
                <p>
                    O ponto de extremidade de introspecção do token são utilizado quando servidor de recursos precisar verificar se um Token
                    de acesso está ativo ou quiser mais metadados sobre ele, especialmente para <a href="https://auth0.com/docs/tokens/access-tokens" target="_black">tokens de acessos</a>,
                    o ponto extremidade de introspecção do token é a resposta.
                </p>

                <p><b>NOTA:</b> Está disponíveis como <code>introspection_endpoint</code> na resposta do endpoint de configuração do OpenID.</p>

                <p>
                    <b>URL</b> <code className="type_method">POST</code>  <code>https://sso.euca.tur.br/auth/realms/eulabs/protocol/openid-connect/token/introspect</code>

                    <div style = {{boxSizing: "border-box" }}>
                        <center>
                        <table>
                            <tbody>
                                <tr>
                                    <th> Tipo do Paramêtro </th> 
                                    <td> Paramêtro </td> 
                                    <td> Valor </td> 
                                </tr>
                                <tr>
                                <th>application/x-www-form-urlencoded</th> 
                                    <th><code>token</code></th> 
                                    <td>
                                        Informe seu token de acesso
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>client_id</code></th> 
                                    <td>
                                        informar seu client_id
                                    </td>
                                </tr>
                                <tr>
                                    <th>application/x-www-form-urlencoded</th> 
                                    <th><code>client_secret</code></th> 
                                    <td>
                                      Seu hash do client
                                    </td>
                                </tr>
                            </tbody> 
                        </table>
                        </center>
                  </div>
                </p>




            </div>


            <h3>Segue abaixo algumas implementações utilizando os protocolo OAuth 2.0 e OIDC para o Keycloak</h3>
            <p>
                <a href="https://github.com/react-keycloak/react-native-keycloak" target="_black">React Native Keycloak</a>
            </p>
            
            <p>
                <a href="https://medium.com/keycloak/secure-react-app-with-keycloak-4a65614f7be2" target="_black">React Keycloak</a>
            </p>

            <p>
                <a href="https://github.com/dsb-norge/vue-keycloak-js" target="_black">Vue Keycloak</a>
            </p>

            <p>
                <a href="https://medium.com/keycloak/secure-vue-js-app-with-keycloak-94814181e344" target="_black">Secure Vue.js app with Keycloak</a>
            </p>

            <p>
                <a href="https://github.com/Wobow/keycloak-cordova-example" target="_black">keycloak-cordova-example</a>
            </p>

            <p>
                <a href="https://github.com/keycloak/keycloak-quickstarts" target="_black">Keycloak Quickstarts</a>
            </p>




            </div>
            
        )
        }

        export default SSO;